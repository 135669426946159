import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Customers = ({ data }) => (
  <Layout>
    <SEO
      title="Data Protection and GDPR Compliance"
      description="Data Protection is our core business. That's why we have strong measures in place to comply with
      the GDPR and other data protection laws."
    />

    <section className="py-8 pt-md-11">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <h1 className="display-1 font-weight-bold">Trust and Compliance</h1>
            <p className="lead text-muted mb-6">
              Data Protection is our core business.
              <br /> That's why we have strong measures in place to comply with
              the <mark>GDPR</mark> and other data protection laws.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="my-6 my-md-8" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8">
            <h3 className="mb-5">Measures</h3>
            <div className="d-flex">
              <span className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <p className="text-gray-800">
                Images are processed in GDPR-certified data centers in the
                <mark>
                  <strong>European Union</strong>
                </mark>
              </p>
            </div>
            <div className="d-flex">
              <span className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <p className="text-gray-800">
                <mark>
                  <strong>External Data Protection Officer</strong>
                </mark>{" "}
                at service
              </p>
            </div>
            <div className="d-flex">
              <span className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <p className="text-gray-800">
                We do not use your images to improve our technology (except if
                specifically requested)
              </p>
            </div>
            <div className="d-flex">
              <span className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <p className="text-gray-800">
                <mark>
                  <strong>Data Minimization</strong>
                </mark>{" "}
                Your image data is deleted after successful delivery
              </p>
            </div>
            <div className="d-flex">
              <span className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <p className="text-gray-800">
                Annual and unannounced{" "}
                <mark>
                  <strong>Data Protection Audits</strong>
                </mark>
              </p>
            </div>
            <div className="d-flex">
              <span className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <p className="text-gray-800">
                <mark>
                  <strong>Data Processing Register</strong>
                </mark>{" "}
                A register where the data processor (us) documents how personal
                data is being processed.
              </p>
            </div>
            <div className="d-flex">
              <span className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <p className="text-gray-800">
                <mark>
                  <strong>Data Protection Impact Assessment (DPIA)</strong>
                </mark>{" "}
                Required by the GDPR for any projects that involve "high risk"
                to other people's personal data.
              </p>
            </div>
            <h3 className="mb-5">Templates and Documents</h3>
            <p className="text-gray-800">
              To make our collaboration as easy as possible, we have prepared
              documents and templates, to safe time and focus completely on your
              image blurring needs.
            </p>
            <div className="d-flex">
              <span className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <p className="text-gray-800">
                <mark>
                  <strong>Data Processing Agreement</strong>
                </mark>{" "}
                Regulates the scope and purpose of data processing between the
                data controller (you) and data processor (us). The GDPR requires
                a controller who engages a processor to enter into a written
                contract (Article 28.3 GDPR).
              </p>
            </div>
            <div className="d-flex">
              <span className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <p className="text-gray-800">
                <mark>
                  <strong>Technical and Organizational Measures (TOM)</strong>
                </mark>{" "}
                A list of measures how the data processor (us) ensures data
                protection and safety in their processes and facilities.
              </p>
            </div>

            <div className="d-flex">
              <span className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <p className="text-gray-800 mb-6 mb-md-0">
                <mark>
                  <strong>Non-Disclosure Agreement (NDA)</strong>
                </mark>
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card shadow-lift border-primary">
              <div className="card-body">
                <h4 className="font-weight-bold text-uppercase">Learn</h4>
                <p className="font-size-sm text-gray-800 mb-5">
                  Data protection know-how for your industry.
                </p>
                <h5 className="font-weight-bold text-uppercase text-gray-700 mb-2">
                  Videos
                </h5>
                <p>
                  <Link to="/contact/" className="font-size-sm">
                    » Data Protection meets Mobile Mapping (Webinar)
                  </Link>
                </p>
                <h5 className="font-weight-bold text-uppercase text-gray-700 mb-2">
                  Blog Posts
                </h5>
                <p>
                  <Link
                    to="/blog/data-protection-for-mobile-mapping-expert/"
                    className="font-size-sm"
                  >
                    » Data Protection for Mobile Mapping (EN)
                  </Link>
                </p>
                <p>
                  <Link
                    to="/blog/datenschutz-fuer-mobile-mapping/"
                    className="font-size-sm"
                  >
                    » Datenschutz für Mobile Mapping (DE)
                  </Link>
                </p>
                <p>
                  <Link
                    to="/blog/drone-legislation-eu-data-privacy/"
                    className="font-size-sm"
                  >
                    » Drone Legislation in the EU in regards to Data Privacy
                  </Link>
                </p>
                <p>
                  <Link
                    to="/blog/autonomous-vehicle-data-privacy/"
                    className="font-size-sm"
                  >
                    » Who will take care of Data Privacy on Autonomous Vehicles?
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Customers
